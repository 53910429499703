td.centered {
    text-align: center;
}

.last_date {
    width: 150px;
}

.alert_ready {
    color: #27b600;
    font-weight: bold;
}

.alert_alerted {
    color: #b00101;
    font-weight: bold;
}

.alert_outdated {
    color: #a8a8a8;
    font-weight: bold;
}

div.box {
    background-color: #f5f5f5;
    box-shadow: none;
    border: 1px solid rgba(10, 10, 10, .2);
}
