.full-height-graph-box {
    height: 300px
}

.half-height-graph-box {
    height: 150px
}

div.custom-tooltip {
    background-color: rgb(255, 255, 255);
    border: 1px solid rgb(204, 204, 204);
    padding: 10px;
    white-space: nowrap;
}
