.leaflet-popup-content {
    font-size: 0.85rem;
}
.leaflet-popup-content td.second-column {
    display: flex;
    justify-content: end;
    min-width: 100px;
}

.leaflet-popup-content tr.header > td {
    vertical-align: middle;
    padding-bottom: 0.5rem;
}

.leaflet-popup-content .popup-sensor-name {
    font-size: 1.3rem;
}

.leaflet-popup-content a.popup-sensor-name {
    text-decoration: underline;
}

.leaflet-popup-content div.icon {
    height: 30px;
}

.leaflet-popup-content div.signal-strength {
    width: 30px;
    margin-right: 5px;
    display: inline-block;
}

.leaflet-popup-content div.signal-strength > img {
    width: 100%;
    height: 100%;
}

.leaflet-popup-content div.rain-intensity {
    width: 50px;
}

.leaflet-popup-content div.wide-icon {
    width: 100px;
}

.leaflet-popup-content div.icon > img {
    height: 100%;
    width: 100%;
}

.leaflet-popup-content div.leaflet-wetness {
    width: 55px;
}

.leaflet-popup-content .second-column.measurement-data {
    font-weight: bold;
}

.leaflet-popup-content hr {
    margin: 0.2rem;
}

.leaflet-popup-content a.icon-text  {
    text-decoration: underline;
}

img.mobile-map-logo {
    position: absolute;
}

.mobile-map-legend {
    position: absolute;
}

.mobile-map-legend > img {
    width: 100%;
    height: auto
}

.desktop-map-legend {
    position: absolute;
    bottom: 10px;
    right: 10px;
    height: 10%;
    width: 20%;
    flex-direction: column-reverse;
}

.desktop-map-legend img {
    margin-left: 10px;
    margin-bottom: 10px;
}

@media screen and (max-width: 768px) {
    img.mobile-map-logo {
        top: 0;
        left: 0;
        width: 100%;
        height: auto
    }

    .mobile-map-legend {
        bottom: 0;
        left: 0;
        width: 100%;
        height: auto
    }
}

@media screen and (min-width: 769px) and (max-width: 1215px) {
    img.mobile-map-logo {
        top: 0;
        left: 20%;
        width: 60%;
        height: auto;
    }

    .mobile-map-legend {
        bottom: 0;
        left: 20%;
        width: 60%;
        height: auto;
    }
}

@media screen and (max-width: 1216px) {
    .desktop-map-legend {
        display: none;
    }
}

@media screen and (min-width: 1216px) {
    .mobile-map-logo {
        display: none;
    }

    .mobile-map-legend {
        display: none;
    }

    .desktop-map-legend {
        display: flex;
    }
}
